export default class WorkPackageEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#work_package_uuid") ? (elem.querySelector("#work_package_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#work_package_name") as HTMLInputElement).value,
            default_estimation: (elem.querySelector("#work_package_default_estimation") as HTMLInputElement).value,
            min: (elem.querySelector("#work_package_min") as HTMLInputElement).value,
            price: (elem.querySelector("#work_package_price") as HTMLInputElement).value,
            capable_users: elem.querySelector("#work_package_capable_users") ? Array.from(elem.querySelectorAll("#work_package_capable_users option:checked")).map(o => o.value) : [],

        }
    }
}